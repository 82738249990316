.App {
  text-align: center;
}

.app-container {
  display: flex;
  font-family: "Arial", sans-serif; /* 更改字体为 Arial 或其他您喜欢的字体 */
}

.input-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.floating-window {
  position: fixed;
  width: 250px; /* 设置固定宽度 */
  height: 470px; 
  top: 120px; /* 调整top属性值以控制上边距 */
  right: 30px; /* 调整left属性值以控制左边距 */
  background-color: rgba(255, 255, 255, 0.7); /* 添加透明度 */
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-in-out;
  border-radius: 10px;
  font-size: 20px; /* 更改字体大小 */
  overflow: 'auto',
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
}

.close_button {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  font-weight: bold; /* 加粗字体 */
  position: absolute; /* 使用绝对定位 */
  top: 7px; /* 距离顶部的距离 */
  right: 15px; /* 距离右侧的距离 */
}


.active-layout {
  background-color: black;
  color: white;
  font-weight: bold; /* 文本加粗 */
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
}

.spinner {
  animation: rotate 1s linear infinite;
  display: inline-block;
  margin-left: 5px; /* 调整加载指示器与按钮文本之间的间距 */
}

table {
  border-collapse: collapse;
  width: 100%;
}

table, th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  font-weight: bold;
  font-size: 15px;
}
/* You can add more styling as needed */

[data-amplify-authenticator] [data-amplify-router] fieldset {
  display: none;
}
[data-amplify-authenticator] .amplify-divider {
  display: none;
}
[data-amplify-authenticator] form div:not(.federated-sign-in-container) button {
  display: none;
}
[data-amplify-authenticator] form + [data-amplify-footer] {
  display: none;
}